import * as yup from 'yup'
import { ChatType, ContributorInput, DashboardInput, GenerateSetlistDefinitionInput, GenerateSetlistRequestInput, MessageInput, MessageType, NotificationPreferencesInput, NotificationResourceType, PaginationInput, PcoSong, Permissions, PipelineJobsFilter, ReelInput, ReelStatInput, SearchSongsFilters, ServiceInput, SetlistFeedbackSubmissionInput, SetlistInput, SongInput, SongWeightInput, SortDirection, TagsByCategoryFilter, TeamActionActions, TeamInput, TeamSongFeedbackSubmissionInput, TeamSongInput, TestNotificationsInput, Trend, UserInput, VideoCollectionInput, VideoCollectionStatInput, VideoInput, VideoStatInput } from './index'

export const ChatTypeSchema = yup.mixed().oneOf([ChatType.Dm, ChatType.Group, ChatType.Video]);

export function ContributorInputSchema(): yup.SchemaOf<ContributorInput> {
  return yup.object({
    description: yup.string(),
    imageUrl: yup.string(),
    name: yup.string().defined(),
    role: yup.string().defined()
  })
}

export function DashboardInputSchema(): yup.SchemaOf<DashboardInput> {
  return yup.object({
    videoCollectionIds: yup.array().of(yup.string().defined()).defined()
  })
}

export function GenerateSetlistDefinitionInputSchema(): yup.SchemaOf<GenerateSetlistDefinitionInput> {
  return yup.object({
    global: yup.lazy(() => yup.mixed()) as never,
    positions: yup.array().of(yup.lazy(() => yup.mixed().defined()) as never).defined()
  })
}

export function GenerateSetlistRequestInputSchema(): yup.SchemaOf<GenerateSetlistRequestInput> {
  return yup.object({
    emotionTags: yup.array().of(yup.string().defined()).optional(),
    energyTags: yup.array().of(yup.string().defined()).optional(),
    gospelTags: yup.array().of(yup.string().defined()).optional(),
    isPostSermon: yup.boolean(),
    rotationStatus: yup.array().of(yup.string().defined()).optional(),
    searchTerm: yup.string(),
    songTypeTags: yup.array().of(yup.string().defined()).optional()
  })
}

export function MessageInputSchema(): yup.SchemaOf<MessageInput> {
  return yup.object({
    content: yup.string().defined(),
    id: yup.string().defined(),
    metadata: yup.string(),
    type: yup.mixed()
  })
}

export const MessageTypeSchema = yup.mixed().oneOf([MessageType.Setlist, MessageType.Songlist, MessageType.Status, MessageType.Text]);

export function NotificationPreferencesInputSchema(): yup.SchemaOf<NotificationPreferencesInput> {
  return yup.object({
    chat: yup.boolean().defined(),
    groupChat: yup.boolean().defined(),
    videoChat: yup.boolean().defined(),
    videoChatActiveOnly: yup.boolean().defined()
  })
}

export const NotificationResourceTypeSchema = yup.mixed().oneOf([NotificationResourceType.Dm, NotificationResourceType.GroupChat, NotificationResourceType.VideoChat]);

export function PaginationInputSchema(): yup.SchemaOf<PaginationInput> {
  return yup.object({
    afterDate: yup.string(),
    direction: yup.mixed(),
    limit: yup.number().defined(),
    offset: yup.number().defined()
  })
}

export function PcoSongSchema(): yup.SchemaOf<PcoSong> {
  return yup.object({
    artist: yup.string().defined(),
    author: yup.string(),
    ccli: yup.number(),
    name: yup.string().defined(),
    recordedKey: yup.string()
  })
}

export const PermissionsSchema = yup.mixed().oneOf([Permissions.ChargebeeSessionGet, Permissions.ChargebeeSubscriptionCheck, Permissions.ChatCreate, Permissions.ChatUpdate, Permissions.ContributorCreate, Permissions.ContributorDelete, Permissions.ContributorUpdate, Permissions.DashboardCreate, Permissions.DashboardGet, Permissions.DashboardUpdate, Permissions.DevFeatureRead, Permissions.DevtoolsRead, Permissions.InviteCreate, Permissions.InviteDelete, Permissions.MembershipCreate, Permissions.MembershipDelete, Permissions.MembershipUpdate, Permissions.ReelCreate, Permissions.ReelDelete, Permissions.ReelFeedGet, Permissions.ReelGet, Permissions.ReelList, Permissions.ReelStatCreate, Permissions.ReelStatUpdate, Permissions.ReelUpdate, Permissions.ServiceCreate, Permissions.ServiceDelete, Permissions.ServiceGet, Permissions.ServiceList, Permissions.ServiceUpdate, Permissions.SetlistCreate, Permissions.SetlistDelete, Permissions.SetlistGet, Permissions.SetlistList, Permissions.SetlistUpdate, Permissions.SongCreate, Permissions.SongDelete, Permissions.SongList, Permissions.SongUpdate, Permissions.SongWeightCreate, Permissions.SongWeightDelete, Permissions.SongWeightGet, Permissions.SongWeightList, Permissions.SongWeightUpdate, Permissions.SonglistUpdate, Permissions.SubscriptionCreate, Permissions.SubscriptionUpdate, Permissions.TeamDelete, Permissions.TeamList, Permissions.TeamSongCreate, Permissions.TeamSongDelete, Permissions.TeamSongList, Permissions.TeamSongUpdate, Permissions.TeamUpdate, Permissions.VideoChatCreate, Permissions.VideoChatUpdate, Permissions.VideoCollectionCreate, Permissions.VideoCollectionDelete, Permissions.VideoCollectionGet, Permissions.VideoCollectionList, Permissions.VideoCollectionUpdate, Permissions.VideoCreate, Permissions.VideoDelete, Permissions.VideoGet, Permissions.VideoList, Permissions.VideoUpdate, Permissions.WeightedTeamSongList]);

export function PipelineJobsFilterSchema(): yup.SchemaOf<PipelineJobsFilter> {
  return yup.object({
    srcVideo: yup.string()
  })
}

export function ReelInputSchema(): yup.SchemaOf<ReelInput> {
  return yup.object({
    name: yup.string().defined(),
    posterUrl: yup.string().defined(),
    url: yup.string().defined(),
    videoId: yup.string().defined()
  })
}

export function ReelStatInputSchema(): yup.SchemaOf<ReelStatInput> {
  return yup.object({
    liked: yup.boolean().defined()
  })
}

export function SearchSongsFiltersSchema(): yup.SchemaOf<SearchSongsFilters> {
  return yup.object({
    tags: yup.array().of(yup.string().defined()).optional(),
    tagsByCategory: yup.mixed(),
    term: yup.string()
  })
}

export function ServiceInputSchema(): yup.SchemaOf<ServiceInput> {
  return yup.object({
    day: yup.number().defined(),
    name: yup.string().defined(),
    startAt: yup.string().defined()
  })
}

export function SetlistFeedbackSubmissionInputSchema(): yup.SchemaOf<SetlistFeedbackSubmissionInput> {
  return yup.object({
    setlistId: yup.string().defined(),
    teamSongFeedbackSubmissions: yup.array().of(yup.lazy(() => yup.mixed().defined()) as never).defined()
  })
}

export function SetlistInputSchema(): yup.SchemaOf<SetlistInput> {
  return yup.object({
    chatId: yup.string().defined(),
    draft: yup.boolean(),
    serviceId: yup.string(),
    startAt: yup.string().defined(),
    teamSongIds: yup.array().of(yup.string().defined()).defined()
  })
}

export function SongInputSchema(): yup.SchemaOf<SongInput> {
  return yup.object({
    appleMusicId: yup.string(),
    artist: yup.string().defined(),
    artworkUrl: yup.string(),
    ccli: yup.number(),
    emotionTags: yup.array().of(yup.string().defined()).optional(),
    energyTags: yup.array().of(yup.string().defined()).optional(),
    gospelTags: yup.array().of(yup.string().defined()).optional(),
    lyrics: yup.string(),
    name: yup.string().defined(),
    recommendedKey: yup.string(),
    recordedKey: yup.string(),
    songTypeTags: yup.array().of(yup.string().defined()).optional(),
    spotifyId: yup.string(),
    variations: yup.array().of(yup.string().defined()).optional(),
    youtubeId: yup.string()
  })
}

export function SongWeightInputSchema(): yup.SchemaOf<SongWeightInput> {
  return yup.object({
    description: yup.string(),
    function: yup.string().defined(),
    highValue: yup.number().defined(),
    key: yup.string().defined(),
    lowValue: yup.number().defined(),
    name: yup.string().defined()
  })
}

export const SortDirectionSchema = yup.mixed().oneOf([SortDirection.Asc, SortDirection.Desc]);

export function TagsByCategoryFilterSchema(): yup.SchemaOf<TagsByCategoryFilter> {
  return yup.object({
    emotionTags: yup.array().of(yup.string().defined()).defined(),
    energyTags: yup.array().of(yup.string().defined()).defined(),
    gospelTags: yup.array().of(yup.string().defined()).defined(),
    songTypeTags: yup.array().of(yup.string().defined()).defined()
  })
}

export const TeamActionActionsSchema = yup.mixed().oneOf([TeamActionActions.ChatChanged, TeamActionActions.ChatLastSeenChanged, TeamActionActions.ChatMessageSent, TeamActionActions.ChatReactionSent, TeamActionActions.ChatTypingStatus, TeamActionActions.ServiceChanged, TeamActionActions.SetlistsChanged, TeamActionActions.TeamChanged, TeamActionActions.TeamSongsChanged, TeamActionActions.VideoChatChanged, TeamActionActions.VideoChatTypingStatus]);

export function TeamInputSchema(): yup.SchemaOf<TeamInput> {
  return yup.object({
    chartRepositoryUrl: yup.string(),
    name: yup.string()
  })
}

export function TeamSongFeedbackSubmissionInputSchema(): yup.SchemaOf<TeamSongFeedbackSubmissionInput> {
  return yup.object({
    congregationalScore: yup.number().defined(),
    personalScore: yup.number().defined(),
    teamScore: yup.number().defined(),
    teamSongId: yup.string().defined()
  })
}

export function TeamSongInputSchema(): yup.SchemaOf<TeamSongInput> {
  return yup.object({
    appleMusicId: yup.string(),
    artist: yup.string().defined(),
    artworkUrl: yup.string(),
    ccli: yup.number(),
    defaultKey: yup.string(),
    emotionTags: yup.array().of(yup.string().defined()).optional(),
    energyTags: yup.array().of(yup.string().defined()).optional(),
    gospelTags: yup.array().of(yup.string().defined()).optional(),
    lyrics: yup.string(),
    name: yup.string().defined(),
    new: yup.boolean(),
    recommendedKey: yup.string(),
    recordedKey: yup.string(),
    rotationStatus: yup.string(),
    songTypeTags: yup.array().of(yup.string().defined()).optional(),
    spotifyId: yup.string(),
    youtubeId: yup.string()
  })
}

export function TestNotificationsInputSchema(): yup.SchemaOf<TestNotificationsInput> {
  return yup.object({
    delay: yup.number(),
    message: yup.string()
  })
}

export const TrendSchema = yup.mixed().oneOf([Trend.Down, Trend.Flat, Trend.Up]);

export function UserInputSchema(): yup.SchemaOf<UserInput> {
  return yup.object({
    displayName: yup.string(),
    email: yup.string(),
    notificationId: yup.string(),
    notificationPreferences: yup.lazy(() => yup.mixed()) as never
  })
}

export function VideoCollectionInputSchema(): yup.SchemaOf<VideoCollectionInput> {
  return yup.object({
    hex: yup.string().defined(),
    name: yup.string().defined(),
    onboarding: yup.boolean().defined(),
    paid: yup.boolean().defined(),
    videoIds: yup.array().of(yup.string().defined()).defined()
  })
}

export function VideoCollectionStatInputSchema(): yup.SchemaOf<VideoCollectionStatInput> {
  return yup.object({
    completed: yup.boolean().defined()
  })
}

export function VideoInputSchema(): yup.SchemaOf<VideoInput> {
  return yup.object({
    contributorId: yup.string(),
    description: yup.string(),
    duration: yup.number().defined(),
    name: yup.string().defined(),
    paid: yup.boolean().defined(),
    posterUrl: yup.string().defined(),
    thumbnailUrl: yup.string().defined(),
    url: yup.string().defined()
  })
}

export function VideoStatInputSchema(): yup.SchemaOf<VideoStatInput> {
  return yup.object({
    percentagePlayed: yup.number().defined()
  })
}
