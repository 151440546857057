import React, { PropsWithChildren, ReactNode } from 'react';

import {
	aiSettings,
	assetsUrl,
	contributorsUrl,
	dashboardUrl,
	generateSetlist,
	pipelineJobsUrl,
	reelsUrl,
	songsUrl,
	songWeights,
	videoCollectionsUrl,
	videosUrl,
} from '@/config/urls';
import { useAuth } from '@/providers/AuthProvider';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
	Avatar,
	Box,
	Button,
	Container,
	Flex,
	HStack,
	IconButton,
	Image,
	Link,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Stack,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';

const Links: {
	label: string;
	url: string;
}[] = [
	{
		label: 'Dashboard',
		url: dashboardUrl,
	},
	{
		label: 'Video Collections',
		url: videoCollectionsUrl,
	},
	{
		label: 'Videos',
		url: videosUrl,
	},
	{
		label: 'Reels',
		url: reelsUrl,
	},
	{
		label: 'Songs',
		url: songsUrl,
	},
	{
		label: 'Contributors',
		url: contributorsUrl,
	},
	{
		label: 'Assets',
		url: assetsUrl,
	},
	{
		label: 'Jobs',
		url: pipelineJobsUrl,
	},
];

const AiLinks: {
	label: string;
	url: string;
}[] = [
	{
		label: 'Explore Weighted Songs',
		url: aiSettings,
	},
	{
		label: 'Generate Setlist',
		url: generateSetlist,
	},
	{
		label: 'Song Weights',
		url: songWeights,
	},
];

const NavLink = ({
	onClick,
	children,
}: {
	onClick?: () => void;
	children: ReactNode;
}) => (
	<Link
		px={2}
		py={1}
		rounded={'md'}
		_hover={{
			textDecoration: 'none',
			bg: useColorModeValue('grey.200', 'grey.700'),
		}}
		onClick={onClick}
	>
		{children}
	</Link>
);

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { user, logout } = useAuth();
	const navigate = useNavigate();

	return (
		<>
			<Box bg={useColorModeValue('grey.100', 'grey.900')} px={4}>
				<Flex
					h={16}
					alignItems={'center'}
					justifyContent={'space-between'}
				>
					<IconButton
						size={'md'}
						icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
						aria-label={'Open Menu'}
						display={{ md: 'none' }}
						onClick={isOpen ? onClose : onOpen}
					/>
					<HStack spacing={8} alignItems={'center'}>
						<Image w="100px" src="/asaph-logo-black.svg" />
						<HStack
							as={'nav'}
							spacing={4}
							display={{ base: 'none', md: 'flex' }}
						>
							{Links.map(({ label, url }) => (
								<NavLink
									key={url}
									onClick={() => navigate(url)}
								>
									{label}
								</NavLink>
							))}
							<Box position="relative">
								<Menu>
									<MenuButton as={NavLink}>
										AI Settings
									</MenuButton>
									<MenuList>
										{AiLinks.map(({ label, url }) => (
											<MenuItem
												key={url}
												onClick={() => navigate(url)}
											>
												{label}
											</MenuItem>
										))}
									</MenuList>
								</Menu>
							</Box>
						</HStack>
					</HStack>
					<Flex alignItems={'center'}>
						<Menu>
							<MenuButton
								as={Button}
								rounded={'full'}
								variant={'link'}
								cursor={'pointer'}
								minW={0}
							>
								<Avatar
									size={'sm'}
									name={user.displayName || user.email}
								/>
							</MenuButton>
							<MenuList>
								<MenuItem disabled>{user.email}</MenuItem>
								<MenuDivider />
								<MenuItem onClick={logout}>Logout</MenuItem>
							</MenuList>
						</Menu>
					</Flex>
				</Flex>

				{isOpen ? (
					<Box pb={4} display={{ md: 'none' }}>
						<Stack as={'nav'} spacing={4}>
							{Links.map(({ label, url }) => (
								<NavLink
									key={url}
									onClick={() => navigate(url)}
								>
									{label}
								</NavLink>
							))}
						</Stack>
					</Box>
				) : null}
			</Box>

			<Container>
				<Box p={4}>{children}</Box>
			</Container>
		</>
	);
};
